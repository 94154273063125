@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap');

:root {
    --primary-color: #000000;
    --secondary-color: #FFFFFF;
    --accent-color: #007AFF;
    --text-color: #FFFFFF;
    --text-color-muted: #8E8E93;
    --background-dark: #000000;
    --card-background: #1C1C1E;
}

body {
    font-family: 'Poppins', sans-serif;
    margin: 0;
    padding: 0;
    background-color: var(--background-dark);
    color: var(--text-color);
    overflow-x: hidden;
}

body, html {
    width: 100%;
    overflow-x: hidden;
  }
  
  #root {
    width: 100%;
    max-width: 100vw;
    overflow-x: hidden;
  }
  
  /* Minden szekció konténerére */
  .section-container {
    width: 100%;
    max-width: 100vw;
    overflow-x: hidden;
  }

h1, h2, h3, h4, h5, h6 {
    font-family: 'Poppins', sans-serif;
    color: var(--secondary-color);
}

/* Header Styles */
header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    padding: 10px 20px;
    background-color: var(--primary-color);
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 1000;
    margin-bottom: auto;
}

.logo-container {
    display: flex;
    align-items: center;
}

.logo-container a {
    display: inline-block;
    text-decoration: none;
    -webkit-tap-highlight-color: transparent;
}

.logo-container a:focus {
    outline: none;
    box-shadow: none;
}

.logo {
    height: 40px;
    transition: all 0.3s ease;
    cursor: pointer;
user-select: none;
}

.logo:hover {
    transform: scale(1.1);
    filter: brightness(0) saturate(100%) invert(40%) sepia(52%) saturate(1000%) hue-rotate(190deg) brightness(102%) contrast(101%);
}

.logo:focus {
    outline: none;
    box-shadow: none;
}

.header-nav {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.header-nav a {
    position: relative;
    font-size: 0.6em;
    color: var(--text-color);
    text-decoration: none;
    padding: 5px 15px;
    transition: .5s;
    margin-left: 10px;
}

.header-nav a:hover,
.header-nav a.active {
    color: var(--accent-color);
}

.header-nav a span {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    border-bottom: 2px solid var(--accent-color);
    border-radius: 15px;
    transform: scale(0) translateY(50px);
    opacity: 0;
    transition: .5s;
}

.header-nav a:hover span,
.header-nav a.active span {
    transform: scale(1) translateY(0);
    opacity: 1;
}

.header-nav a:focus {
    outline: none;
}

.phone-icon-container {
    position: relative;
    margin-left: 10px;
    margin-right: 30px;
}

.phone-icon {
    color: var(--accent-color);
    transition: color 0.3s ease;
}

.phone-icon:hover {
    color: var(--secondary-color);
}

.tooltip {
    position: absolute;
    top: 100%;
    right: 0;
    background-color: var(--card-background);
    color: var(--text-color);
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 0.9rem;
    white-space: nowrap;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease, visibility 0.3s ease;
}

.phone-icon-container:hover .tooltip {
    opacity: 1;
    visibility: visible;
}

/* Hamburger Menu */
.hamburger-menu {
    display: none;
    cursor: pointer;
}

.hamburger-menu span {
    display: block;
    width: 25px;
    height: 3px;
    background-color: var(--secondary-color);
    margin: 5px 0;
    transition: 0.4s;
}

/* Hero Section */
.hero-section {
    background-image: url('/public/images/image3-desktop.webp');
    background-size: cover;
    background-position: center center;
    min-height: 100vh;
    display: flex;
    align-items: center;
    padding: 0px 5%;
    position: relative;

}
  
.hero-content {
    max-width: 100%;
    text-align: left;
    color: var(--text-color);
    z-index: 2;
    padding: 20px;
}

.hero-content h1 {
    margin-top: 60px;
    font-size: 2.3rem;
    padding-top: 20px; /* Ez biztosítja a konzisztens távolságot a lap tetejétől */
    max-width: 65%;
}

.hero-content h2 {
    font-size: 1.3rem;
    font-weight: 400;
    line-height: 1.4;
    margin-top: 60px;
    margin-bottom: 30px;
    color: #F0F0F0;
    max-width: 50%;
}

@media screen and (min-width: 1072px) and (max-width: 1474px) {
    .hero-section h2 {
        max-width: 70%;
    }
}

@media (max-width: 533px) {
    .hero-content h1  { 
        max-width: 90%;
        font-size: 1.3rem;
        text-align: start;
    }
}

@media screen and (min-width: 533px) and (max-width: 768px) {
    .hero-content h1 {
    font-size: 1.7rem;
    margin-top: 40px;
    text-align: start;
    }
}


@media (max-width: 768px) {
    .hero-section {
        background-image: url('/public/images/image3-mobile.webp');
        min-height: 50vh;
        padding: 60px 5%;
    }
    
    .hero-content {
      max-width: 100%;
    }

}

@media screen and (min-width: 769px) and (max-width: 1024px) {
    .hero-section {
        min-height: 50vh;
        padding-top: 40px;
    }

    .hero-content h1 {
        margin-top: 20px;
        font-size: 2.2rem;
    }
}


/* CTA Button Styles */
.cta-button-wrapper {
    display: inline-flex;
    align-items: center;
    padding: 8px 20px;
    text-decoration: none;
    font-family: 'Poppins', sans-serif;
    font-size: 20px;
    font-weight: 600;
    color: white;
    background: #000000;
    transition: 1s;
    box-shadow: 6px 6px 0 rgb(255, 255, 255);
    transform: skewX(-15deg);
    margin-top: 30px;
    margin-bottom: 30px;
    cursor: pointer;
    height: auto;
}

.cta-button-wrapper:focus {
   outline: none;
}

.cta-button-wrapper:hover {
    transition: 0.5s;
    box-shadow: 10px 10px 0 #ff08f7;
}

.cta-text {
    transform: skewX(15deg);
}

.cta-arrow {
    display: flex;
    align-items: center;
    margin-left: 0;
    transition: 0.5s;
}

.cta-arrow svg {
    width: 55px;
    height: 43px;
    transition: transform 0.3s ease;
    margin-left: 20px;
}

.cta-button-wrapper:hover .cta-arrow {
    margin-right: 45px;
}

.cta-button-wrapper path {
    fill: #FFFFFF;
}

.cta-button-wrapper:hover path.three {
    animation: color_anim 1s infinite 0.2s;
}

.cta-button-wrapper:hover path.one {
    transform: translateX(0%);
    animation: color_anim 1s infinite 0.6s;
}

.cta-button-wrapper:hover path.two {
    transform: translateX(0%);
    animation: color_anim 1s infinite 0.4s;
}

.cta-button-wrapper span {
    transform: skewX(15deg);
}

.cta-button-wrapper path.one {
    transition: 0.4s;
    transform: translateX(-60%);
}

.cta-button-wrapper path.two {
    transition: 0.5s;
    transform: translateX(-30%);
}

@keyframes color_anim {
    0% {
        fill: white;
    }
    50% {
        fill: #ff08f7;
    }
    100% {
        fill: white;
    }
}

.hero-content ul {
    list-style-type: none;
    padding-left: 0;
    font-size: 1rem;
    color: #E0E0E0;
    margin-top: 60px;
}

.hero-content ul li {
    font-size: 0.9rem;
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    text-align: start;
}

.hero-content ul li:before {
    content: '✓';
    color: var(--accent-color);
    font-weight: bold;
    margin-right: 10px;
}

/* MessageIconPopup Styles */
.message-icon-container {
    position: fixed;
    z-index: 1000;
    right: 20px;
    bottom: 20px;
}

.message-icon {
    position: absolute;
    cursor: pointer;
    background-color: var(--primary-color);
    border-radius: 50%;
    padding: 10px;
    box-shadow: 0 0 10px rgba(255, 255, 255, 0.3);
}

.popup {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 20px;
    width: 90%;
    max-width: 400px;
    box-sizing: border-box;
}

.popup, .contact-details {
    position: fixed;
    transition: all 0.3s ease;
    background-color: var(--card-background);
    border-radius: 10px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
    z-index: 1001;
    max-width: 90%;
    max-height: 90vh;
    overflow-y: auto;
}

.popup h2 {
    font-size: 1.1rem;
    margin-top: 0;
    margin-bottom: 15px;
    color: var(--accent-color);
}

.popup p {
    color: var(--text-color);
    font-size: 0.7rem;
    margin-bottom: 15px;
}

.popup input {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid var(--accent-color);
    border-radius: 5px;
    background-color: var(--primary-color);
    color: var(--text-color);
    box-sizing: border-box;
}

.popup-submit-button {
    width: 100%;
    padding: 10px;
    background-color: var(--accent-color);
    color: var(--primary-color);
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-size: 0.7rem;
}

.popup-submit-button:hover {
    background-color: #0056b3;
}

.popup-close-button {
    position: absolute;
    right: 10px;
    top: 10px;
    width: 30px;
    height: 30px;
    background-color: rgba(0, 0, 0, 0.5);
    border: none;
    border-radius: 50%;
    font-size: 18px;
    color: white;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    line-height: 1;
    z-index: 1002;
}

.popup-close-button:hover {
    background-color: rgba(0, 0, 0, 0.7);
}

/* About Us Section */
.about-us-section {
    padding: 80px 5%;
    background-color: var(--primary-color);
    overflow: hidden;
    position: relative;
    z-index: 1;
  }

  .about-us-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #000000;
  }

  .about-us-text {
    width: 100%;
    padding: 20px;
  }

  .about-us-spline-container {
    width: 100%;
    height: 300px;
    position: relative;
  }


.about-us-text h2 {
    font-size: 1.5rem;
    color: var(--secondary-color);
    margin-bottom: 20px;
}

.about-us-text p {
    font-size: 0.85rem;
    color: var(--text-color-muted);
    margin-bottom: 30px;
}

.story-points {
    list-style-type: none;
    padding: 0;
}

.story-points li {
    font-size: 0.85rem;
    color: var(--secondary-color);
    margin-bottom: 10px;
    display: flex;
    align-items: center;
}

.story-points li::before {
    content: '•';
    color: var(--accent-color);
    font-size: 1.5rem;
    margin-right: 10px;
}

/* Problem Solution Section */
.problem-solution-section {
    display: flex;
    flex-direction: column;
    padding: 80px 5%;
    background: var(--primary-color);
    gap: 30px;
}

/**
 * Videó konténer stílusa
 * - Enyhe saroktörés hozzáadva a border-radius tulajdonsággal
 */
#player {
    width: 100%;
    height: 100%;
    border-radius: 10px; /* Enyhe saroktörés */
    overflow: hidden; /* Biztosítja, hogy a videó ne lógjon ki a lekerekített sarkokon */
}

.problem-solution-content,
.problem-solution-video {
    width: 100%;
    margin: 0;
    padding: 0;
}

.problem-solution-video {
    width: 100%;
    max-width: 560px;
    margin: 0 auto;
    aspect-ratio: 16 / 9;
    background-color: #000;
    display: flex;
    justify-content: center;
    align-items: center;
}

.lazy-video {
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: opacity 0.3s ease-in;
    border-radius: 15px;
}

.lazy-video.loaded {
    opacity: 1;
}

.problem-solution-content {
    padding: 20px;
    z-index: 2;
}

.problem-solution-content {
    flex: 1;
    padding: 20px;
    z-index: 2;
}

.problem-solution-content h3 {
    font-size: 1.1rem;
    font-weight: 500;
    margin-bottom: 30px;
    color: var(--secondary-color);
}

.problem-solution-content p, .problem-solution-content ul {
    font-size: 0.75rem;
    line-height: 1.6;
    margin-bottom: 20px;
    color: var(--text-color-muted);
}

.problem-solution-content ul {
    padding-left: 20px;
}

.problem-solution-content .highlight {
    font-weight: 600;
    color: var(--secondary-color);
}

.problem-solution-content .strong {
    font-weight: 700;
    color: var(--secondary-color);
}

/* Benefits Section Styles */
.benefits-section {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    padding: 80px 5%;
    background-color: var(--primary-color);
    margin-top: 40px;
}

.cards-section {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: stretch;
    width: 100%;
}

/**
 * Card stílusok
 * - Módosítva a szélesség és margó, hogy megfelelően elrendezze a kártyákat különböző képernyőméreteken
 */
.card {
    width: calc(33.33% - 20px);
    margin: 0 10px 20px;
    flex-grow: 0;
    flex-shrink: 0;
    perspective: 1000px;
    height: 300px;
    border-radius: 15px;
    overflow: hidden;
}

.card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.8s;
    transform-style: preserve-3d;
    transform-origin: center center;
}

.card.flipped .card-inner {
    transform: rotateY(180deg);
}

.card-front, .card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    overflow: auto;
    box-sizing: border-box;
    border-radius: 15px;
}

.card-front, .card-back {
    background-color: var(--card-background);
    color: var(--text-color);
}

.card-front {
    background-color: var(--card-background);
    color: var(--text-color);
}

.card-back {
    background-color: var(--card-background);
    color: var(--text-color);
    transform: rotateY(180deg);
}

.card img {
    width: 70px;
    height: 70px;
    margin-bottom: 10px;
    object-fit: contain;
}

.card h3 {
    font-size: 1rem;
    font-weight: 600;
    color: var(--secondary-color);
    text-align: center;
    margin-bottom: 10px;
}

.card p {
    font-size: 0.8rem;
    line-height: 1.4;
    color: var(--text-color-muted);
    text-align: center;
}

.card .highlight {
    color: var(--accent-color);
    font-weight: 600;
}

/**
 * Új stílus a kártya előlapján lévő instrukcióhoz
 */
.card-instruction {
    font-size: 0.7rem;
    color: var(--accent-color);
    margin-top: 10px;
}

@media (hover: hover) and (pointer: fine) {
    .card:hover {
        transform: translateY(-10px);
    }
    .card:hover .card-front,
    .card:hover .card-back {
        box-shadow: 0 15px 30px rgba(0,0,0,0.2);
    }
}

/* Services Section */
.services-section {
    background: var(--primary-color);
    padding: 80px 5%;
    text-align: center;
}

.services-section h2 {
    font-size: 1.7rem;
    color: var(--secondary-color);
    margin-bottom: 40px;
}

.services-container {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    gap: 20px;
}

.service-card {
    background-color: var(--card-background);
    border: 1px solid var(--text-color-muted);
    border-radius: 15px;
    padding: 20px;
    width: calc(25% - 20px);
    min-width: 200px;
    transition: transform 0.3s ease, border-color 0.3s ease;
    opacity: 0;
    transform: translateY(50px);
}

.service-card:hover {
    transform: translateY(-10px);
    border-color: var(--accent-color);
}

.service-icon {
    font-size: 2.5rem;
    margin-bottom: 20px;
}

.service-card h3 {
    font-size: 1rem;
    color: var(--secondary-color);
    margin-bottom: 15px;
}

.service-card p {
    font-size: 0.8rem;
    line-height: 1.6;
    color: var(--text-color-muted);
}

/* FAQ Section Styles */
.faq-section {
    background-color: var(--primary-color);
    padding: 80px 5%;
    position: relative;
    overflow: hidden;
}

.faq-title {
    text-align: center;
    font-size: 1.6rem;
    color: var(--secondary-color);
    margin-bottom: 40px;
}

.faq-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
}

.faq-item {
    background-color: var(--card-background);
    border: 1px solid var(--text-color-muted);
    border-radius: 10px;
    padding: 15px;
    width: 100%;
    margin-bottom: 15px;
    transition: all 0.3s ease;
    text-align: left;
}

.faq-item:hover {
    transform: translateY(-5px);
    border-color: var(--accent-color);
}

.faq-question {
    font-size: 0.9rem;
    font-weight: 600;
    color: var(--secondary-color);
    margin-bottom: 10px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.faq-question::after {
    content: '+';
    font-size: 1.3rem;
    color: var(--accent-color);
    transition: transform 0.3s ease;
}

.faq-item.active .faq-question::after {
    transform: rotate(45deg);
}

.faq-answer {
    font-size: 0.8rem;
    color: var(--text-color-muted);
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease, opacity 0.3s ease;
    opacity: 0;
    text-align: left;
}

.faq-item.active .faq-answer {
    max-height: 1000px;
    opacity: 1;
    margin-top: 10px;
}

/* Pricing Section */
.pricing-section {
    background-color: var(--primary-color);
    padding: 80px 5%;
    text-align: center;
}

.pricing-section h2 {
    font-size: 1.6rem;
    color: var(--secondary-color);
    margin-bottom: 40px;
}

.pricing-container {
position: relative;
overflow: hidden;
max-width: 1200px; /* Beállítjuk a maximális szélességet */
margin: 0 auto; /* Középre igazítjuk a konténert */
}

.pricing-slider {
    display: flex;
    transition: transform 0.3s ease;
    flex-direction: column; /* Alapértelmezetten oszlopba rendezzük */
    align-items: stretch; /* Középre igazítjuk a kártyákat */
    gap: 20px; /* Egyenlő távolság a kártyák között */
    padding-top: 15px;
}

.slider-arrow {
    display: none; /* Alapértelmezetten elrejtjük a nyilakat */
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    padding: 10px;
    cursor: pointer;
    z-index: 10;
}

.slider-arrow.left {
    left: 10px;
}

.slider-arrow.right {
    right: 10px;
}

@media (min-width: 1200px) {
    .pricing-slider {
        flex-direction: row; /* Vízszintesen rendezzük a kártyákat */
        justify-content: flex-start; /* Balra igazítjuk a kártyákat */
        gap: 15px; /* 15px távolság a kártyák között */
        width: calc(133.33% + 30px); /* 4 kártya szélessége + 3 gap */
        padding-right: 140px;
    }
    
    .pricing-card {
        flex: 0 0 calc(25% - 15px); /* Egyenlő méretű kártyák, figyelembe véve a gap-et */
        max-width: none; /* Eltávolítjuk a max-width-et */
    }

    .slider-arrow {
        display: block; /* Megjelenítjük a nyilakat */
    }
}

/* Minden más méretben egymás alatt lesznek a kártyák */
@media (max-width: 1200px) {
    .pricing-slider {
        flex-direction: column;
        align-items: center;
    }

    .pricing-card {
        width: 100%;
        max-width: 350px;
        margin-bottom: 20px;
    }

    .slider-arrow {
        display: none; /* Elrejtjük a nyilakat */
    }
}

.pricing-card {
    display: flex;
    flex-direction: column;
    background-color: var(--card-background);
    border: 1px solid var(--accent-color);
    border-radius: 15px;
    padding: 30px 20px;
    transition: all 0.3s ease;
    position: relative;
    overflow: hidden;
    width: 100%;
    max-width: 350px;
    margin-bottom: 20px;
}

.pricing-card.popular {
    box-shadow: 0 10px 20px rgba(0, 122, 255, 0.2);
    border: 2px solid var(--accent-color);
}

.pricing-card.hovered {
    transform: translateY(-10px);
    box-shadow: 0 20px 40px rgba(0, 122, 255, 0.3);
}

.pricing-card-image {
    width: 100%;
    height: 140px;
    object-fit: cover;
    border-radius: 10px;
    margin-bottom: 20px;
}

.popular-tag {
    position: absolute;
    top: 35px;
    right: -42px;
    background: var(--accent-color);
    color: var(--secondary-color);
    padding: 5px 30px;
    transform: rotate(45deg);
    font-size: 0.8rem;
    font-weight: bold;
}

.pricing-card h3 {
    font-size: 1.1rem;
    color: var(--secondary-color);
    margin-bottom: 15px;
}

.pricing-card .price {
    font-size: 1.2rem;
    font-weight: 700;
    color: var(--accent-color);
    margin-bottom: 25px;
}

.pricing-card ul {
    list-style-type: none;
    padding: 0;
    margin-bottom: 25px;
    text-align: left;
    flex-grow: 1;
}

.pricing-card ul li {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    color: var(--text-color-muted);
    font-size: 0.7rem;
}

.pricing-card .check-icon {
    color: var(--accent-color);
    margin-right: 10px;
}

.pricing-card-button-wrapper {
    margin-top: auto;
}

.category-buttons,
.subcategory-buttons {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 10px;
  margin-bottom: 20px;
}

.category-button,
.subcategory-button {
  padding: 10px 15px;
  background-color: var(--primary-color);
  color: var(--secondary-color);
  border: 1px solid var(--accent-color);
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-size: 0.8rem;
}

.subcategory-button:hover,
.subcategory-button.active {
  background-color: var(--accent-color);
  color: var(--primary-color);
}

.subcategory-button {
    padding: 8px 15px;
    background-color: transparent;
    color: var(--secondary-color);
    border: none;
    cursor: pointer;
    transition: all 0.3s ease;
    font-size: 0.7rem;
    text-align: left;
  }

/* Alkategória gombok elrejtése alaphelyzetben */
.subcategory-buttons {
    width: 100%;
    display: none;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 10px;
  }



.category-button:hover,
.subcategory-button:hover,
.category-button.active,
.subcategory-button.active {
  background-color: var(--accent-color);
  color: var(--primary-color);
}

.pricing-card .cta-button {
    width: 100%;
    background-color: var(--accent-color);
    color: var(--secondary-color);
    border: none;
    padding: 10px 20px;
    border-radius: 8px;
    font-size: 0.8rem;
    cursor: pointer;
    transition: all 0.3s ease;
}

.pricing-card .cta-button:hover {
    background-color: var(--secondary-color);
    color: var(--primary-color);
}

@media (min-width: 1201px) {
    .pricing-container {
        justify-content: center;
    }
    
    .pricing-card {
        width: calc(30% - 20px);
        max-width: none;
    }
}

@media (min-width: 1025px) and (max-width: 1200px) {
    .pricing-container {
        justify-content: center;
    }
    
    .pricing-card {
        width: calc(33.33% - 20px);
        max-width: none;
    }
}

@media (max-width: 1024px) {
    .pricing-container {
        flex-direction: column;
        align-items: center;
    }

    .pricing-card {
        width: 100%;
        max-width: 350px;
    }
}

@media (max-width: 480px) {
    .pricing-section h2 {
        font-size: 1.1rem;
    }
}

/* Contact Section */
.contact-section {
    background-color: var(--primary-color);
    padding: 80px 5%;
    text-align: center;
    position: relative;
    overflow: hidden;
}

.contact-section h2 {
    font-size: 1.6rem;
    color: var(--secondary-color);
    margin-bottom: 40px;
}

.contact-form {
    max-width: 600px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.form-group {
    width: 100%;
    margin-bottom: 20px;
    position: relative;
}

.form-input {
    width: 90%;
    padding: 12px;
    border: 1px solid var(--accent-color);
    border-radius: 5px;
    background-color: transparent;
    color: var(--text-color);
    font-size: 16px;
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.form-input:focus {
    outline: none;
    border-color: var(--accent-color);
    box-shadow: 0 0 0 2px rgba(0, 122, 255, 0.2);
}

.form-input::placeholder {
    color: var(--accent-color);
}

.form-label {
    position: absolute;
    top: 50%;
    left: 25px;
    transform: translateY(-50%);
    font-size: 13px;
    color: var(--accent-color);
    padding: 0 5px;
    pointer-events: none;
    transition: 0.5s;
    background-color: black;
}

.form-input:focus ~ .form-label,
.form-input:not(:placeholder-shown) ~ .form-label {
    top: 0;
    font-size: 14px;
    background: var(--primary-color);
}

textarea.form-input {
    height: 150px;
    resize: vertical;
}

.category-buttons {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
    margin-bottom: 20px;
  }

  .category-button {
    padding: 10px 15px;
    background-color: var(--primary-color);
    color: var(--secondary-color);
    border: 1px solid var(--accent-color);
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s ease;
    font-size: 0.6rem;
    position: relative;
  }

  .category-button:hover,
  .category-button.active {
    background-color: var(--accent-color);
    color: var(--primary-color);
  }

  .category-button-container {
    position: relative;
  }

  .subcategory-dropdown.open {
    display: flex;
  }

  .subcategory-dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: var(--card-background);
    border: 1px solid var(--accent-color);
    border-top: none;
    border-radius: 0 0 5px 5px;
    display: none;
    flex-direction: column;
    z-index: 10;
  }


.contact-form .submit-button {
    background-color: var(--accent-color);
    color: var(--secondary-color);
    padding: 12px 25px;
    border: none;
    border-radius: 8px;
    font-size: 0.7rem;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
}

.contact-form .submit-button:hover {
    background-color: var(--secondary-color);
    color: var(--primary-color);
    transform: translateY(-3px);
}

/* Footer Styles */
footer {
    background-color: var(--primary-color);
    color: var(--text-color-muted);
    padding: 20px 5%;
    border-top: 1px solid var(--text-color-muted);
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}

.footer-content {
    text-align: left;
    font-size: 0.6rem;
    display: flex;
    justify-content: space-between;
    text-align: left;
    font-size: 0.6rem;
    padding: 10px;
    gap: 20px;
}

.footer-links {
    margin-top: 10px;
}

.footer-links a {
    color: var(--accent-color);
    text-decoration: none;
    margin-right: 20px;
    transition: color 0.3s ease;
    font-size: 0.65rem;
}

.footer-links a:hover {
    color: var(--secondary-color);
}

.contact-info {
    flex: 1;
    text-align: right;
    position: relative;
}

.contact-hover {
    cursor: pointer;
    position: relative;
}

.contact-details {
    position: absolute;
    display: none;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
    background-color: var(--card-background);
    padding: 15px;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    margin-top: 10px;
    z-index: 1000;
    width: auto;
    min-width: 200px;
}

.contact-details::after {
    content: '';
    position: relative;
    bottom: -10px;
    right: 10px;
    border-width: 10px 10px 0;
    border-style: solid;
    border-color: var(--card-background) transparent transparent transparent;
}

.contact-hover:hover .contact-details {
    display: block;
}

.contact-details svg {
    margin-right: 5px;
}

.contact-info p {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 2px 0;
    font-size: 0.75rem;
    margin-right: 40px;
}

.contact-info svg {
    margin-right: 10px;
}

/* Parallax effect */
.parallax {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: #000000;
}

/* Scroll animation */
.scroll-animate {
    transition: transform 0.5s ease, opacity 0.5s ease;
}

/* Responsive Design */

@media (max-width: 1200px) {
    .card {
        width: calc(50% - 20px);
    }
    
    .pricing-card {
        width: calc(50% - 20px);
    }
}

@media (max-width: 1024px) {
    .hero-content h1 {
        word-break: break-word;
        overflow-wrap: break-word;
        hyphens: auto;
        white-space: normal;
        font-weight: 800;
        display: flex;
    }

    .hero-section {
        flex-direction: column;
      }

      .hero-content,
      .hero-spline-container {
        max-width: 100%;
      }
    
      .hero-spline-container {
        height: 50vh;
        width: 100%;
      }

    .hero-content h2 {
        font-size: 0.8rem;
    }
    .problem-solution-section {
        flex-direction: column;
        padding: 60px 5%;
    }
    .problem-solution-content, .problem-solution-video {
        width: 100%;
        max-width: 100%;
    }
    .benefits-section {
        flex-direction: column;
        align-items: center;
        gap: 30px;
    }
    .card {
        width: 85%;
        max-width: 380px;
        height: 300px;
        margin-bottom: 60px;
    }
    .services-container {
        flex-direction: column;
        align-items: center;
    }
    .service-card {
        width: 85%;
        max-width: 350px;
    }
    .pricing-container {
        flex-direction: column;
        align-items: center;
    }
    .pricing-card {
        width: 100%;
        max-width: 350px;
        margin-bottom: 30px;
        box-sizing: border-box;
    }
    .pricing-card.popular {
        transform: scale(1);
    }
    .about-us-content {
        flex-direction: column;
    }
    .about-us-image, .about-us-text {
        width: 100%;
    }
    footer {
        flex-direction: column;
        text-align: center;
    }
    .footer-content, .contact-info {
        flex: none;
        width: 100%;
        text-align: center;
        margin-bottom: 20px;
    }
    .contact-info p {
        justify-content: center;
    }
}

@media (max-width: 1023px) {
    .problem-solution-section {
        flex-direction: column;
    }

    .problem-solution-content {
        order: -1; /* Ez biztosítja, hogy a tartalom felülre kerüljön */
    }


    .problem-solution-content,
    .problem-solution-video {
        width: 100%;
    }
}

.problem-solution-content h3 {
    font-size: 1.1rem;
    font-weight: 600;
    margin-bottom: 20px;
    color: var(--secondary-color);
}

.problem-solution-content p,
.problem-solution-content ul {
    font-size: 0.85rem;
    line-height: 1.6;
    margin-bottom: 20px;
    color: var(--text-color-muted);
    padding-right: 10px;
}

.problem-solution-content ul {
    padding-left: 20px;
}

.problem-solution-content .highlight {
    font-weight: 600;
    color: var(--secondary-color);
}

.problem-solution-content .strong {
    font-weight: 700;
    color: var(--secondary-color);
}

@media (max-width: 768px) {
    header {
        flex-wrap: nowrap;
        justify-content: space-between;
    }
    .logo {
        height: 30px;
    }
    .logo-container {
        margin-bottom: 0;
    }
    .header-nav {
        display: none;
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        background-color: var(--primary-color);
    }
    .header-nav.open {
        display: flex;
        flex-direction: column;
    }
    .header-nav a {
        margin: 5px 0;
        font-size: 0.7em;
        padding: 3px 10px;
    }
    .hamburger-menu {
        display: block;
        cursor: pointer;
        margin-right: 40px;
    }

    .hero-content {
        text-align: center;
        display: flex;
        flex-direction: column;
    }


      .hero-spline-container {
        height: 40vh;
      }


    .hero-content ul {
        order: 2;
        margin-top: 40px;
        margin-bottom: 60px;
    }
    .animated-title {
        background: none;
        -webkit-background-clip: initial;
        background-clip: initial;
        color: var(--secondary-color);
        font-size: 2rem;
    }
    .hero-content h2 {
        font-size: 1rem;
    }
    .cta-button-wrapper {
        font-size: 16px;
        padding: 6px 12px;
        margin-top: 20px;
        order: 3;
    }
    .problem-solution-section {
        padding: 40px 20px;
    }

    .problem-solution-section {
        flex-direction: column;
        padding: 40px 20px;
    }
    
    .problem-solution-content,
    .problem-solution-video {
        width: 100%;
        max-width: 100%;
    }
    
    .cards-section {
        flex-direction: column;
        align-items: center;
    }
    .card {
        width: 80%;
        margin: 10px auto;
    }
    .card:focus {
        outline: none;
    }
    .benefits-section {
        flex-direction: column;
        align-items: center;
    }
    .contact-form {
        width: 100%;
    }
    .message-icon-container {
        right: 10px;
        bottom: 10px;
    }
    .popup {
        width: 85%;
        max-width: 300px;
        margin: 0 auto;
        left: 10%;
    }
    footer {
        flex-direction: column;
        align-items: center;
        text-align: center;
        padding: 20px 10px;
    }
    .footer-content,
    .footer-links,
    .contact-info {
        width: 100%;
        margin-bottom: 15px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .category-buttons {
        flex-direction: column;
        align-items: stretch;
      }
    
      .category-button,
      .subcategory-button {
        width: 100%;
        margin-bottom: 10px;
      }
    
      .subcategory-dropdown {
        position: static;
        border: none;
        margin-top: 5px;
      }

    .contact-info {
        order: -1;
        margin-right: -35px;
        margin-bottom: -10px;
    }
    .footer-links {
        order: 0;
    }
    .footer-links a {
        margin: 5px 0;
    }
    .footer-content p {
        order: 1;
        margin-top: 15px;
    }
    .contact-hover {
        position: relative;
        cursor: pointer;
    }

    .contact-details p {
        margin: 5px 0;
        text-align: left;
    }
    .contact-hover:focus .contact-details,
    .contact-hover:active .contact-details {
        display: block;
    }}

@media (max-width: 480px) {
    .popup {
        width: 90%;
        max-width: 280px;
        padding: 15px;
    }
    .animated-title {
        font-size: 2rem;
    }
    .hero-content h2 {
        font-size: 1rem;
        font-weight: 600;
        text-align: left;
        margin-right: 60px;
    }

    .contact-form {
        padding: 15px;
      }
    
      .form-input,
      .submit-button {
        font-size: 0.9rem;
      }
    
      .category-button,
      .subcategory-button {
        font-size: 0.75rem;
        padding: 8px 12px;
      }

    .cta-button-wrapper {
        font-size: 16px;
    }
    .about-us-text h2, .problem-solution-content h3, .services-section h2, .faq-title, .pricing-section h2, .contact-section h2 {
        font-size: 1.1rem;
    }
}

/* Accessibility improvements */
.visually-hidden {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0;
}

/* Focus styles for better keyboard navigation */
a:focus, button:focus, input:focus, textarea:focus, select:focus {
    outline: 2px solid var(--accent-color);
    outline-offset: 2px;
    outline: none;
  box-shadow: 0 0 5px rgba(81, 203, 238, 1);
}



/* Dark mode styles (if system preference is set to dark) */
@media (prefers-color-scheme: dark) {
    :root {
        --primary-color: #000000;
        --secondary-color: #FFFFFF;
        --text-color: #FFFFFF;
        --text-color-muted: #AAAAAA;
        --background-dark: #121212;
        --card-background: #1E1E1E;
    }
}

/* High contrast mode styles */
@media (prefers-contrast: high) {
    :root {
        --primary-color: #000000;
        --secondary-color: #FFFFFF;
        --accent-color: #FFFF00;
        --text-color: #FFFFFF;
        --text-color-muted: #CCCCCC;
        --background-dark: #000000;
        --card-background: #000000;
    }
    .card, .service-card, .faq-item, .pricing-card {
        border: 2px solid var(--accent-color);
    }
}

/* Reduced motion styles */
@media (prefers-reduced-motion: reduce) {
    * {
        animation: none !important;
        transition: none !important;
    }
    .scroll-animate {
        transform: none !important;
        opacity: 1 !important;
    }
    .animated-title {
        animation: none;
    }
    .animated-title span {
        animation: none;
        opacity: 1;
        transform: translateY(0);
    }
    .header-nav a span {
        transition: none;
    }
    .card-inner {
        transition: none;
    }
    .card:hover,
    .service-card:hover,
    .faq-item:hover,
    .pricing-card:hover {
        transform: none;
    }
    .message-icon {
        animation: none !important;
    }
}

/* Lazy Loading */
.lazy-load {
    opacity: 1;
    transition: opacity 0.3s ease-in;
}

.lazy-load.loaded {
    opacity: 1;
}

/* Additional Mobile Optimizations */
@media (max-width: 768px) {
    .parallax {
        background-attachment: scroll;
        margin-top: -40px;
    }
    
    .animated-title {
        font-size: 2rem;
    }
    
    .hero-content h2 {
        font-size: 0.95rem;
        margin-bottom: 40px;
        text-align: start;
    }
    
    .cta-button-wrapper {
        font-size: 13px;
        padding: 8px 15px;
    }
    
    .about-us-text h2, 
    .problem-solution-content h3, 
    .services-section h2, 
    .faq-title, 
    .pricing-section h2, 
    .contact-section h2 {
        font-size: 1.3rem;
    }
    

    .service-card {
        width: 60%;
        max-width: none;
    }
    
    .footer-links a {
        display: block;
        margin: 10px 0;
    }
}

/* Performance Optimizations */
@media (max-width: 768px) {
    .benefits-section,
    .services-section,
    .pricing-section {
        contain: content;
    }
}

/* Improved Accessibility */
.skip-to-content {
    position: absolute;
    top: -40px;
    left: 0;
    background: var(--accent-color);
    color: var(--primary-color);
    padding: 8px;
    z-index: 100;
    transition: top 0.3s;
}

.skip-to-content:focus {
    top: 0;
}

/* Enhance form accessibility */
input:focus,
textarea:focus,
select:focus {
    outline: 2px solid var(--accent-color);
    outline-offset: 2px;
}

/* Improve color contrast for better readability */
.text-color-muted {
    color: #B0B0B0;
}

/* Add smooth scrolling for a better user experience */
html {
    scroll-behavior: smooth;
}

/* Ensure proper spacing for touch targets on mobile */
@media (max-width: 768px) {
    .header-nav a,
    .cta-button-wrapper,
    .category-button,
    .footer-links a {
        padding: 12px;
        margin: 5px;
        max-width: fit-content;
    }
}


@media (min-width: 1024px) {
    .problem-solution-section {
        flex-direction: row;
        align-items: center;
    }

    .about-us-spline-container {
        height: 500px;
        transform: scale(1.2);
        transform-origin: center center;
        margin: 20px 0; /* Extra térköz felül és alul */
      }
    .problem-solution-content,
    .problem-solution-video {
        flex: 0 0 calc(50% - 15px);
    }
}

@media (max-width: 767px) {
    .about-us-spline-container {
      transform: scale(0.8);
    }
  }

@media (min-width: 768px) {
    .popup {
        width: 50%;
        max-width: 400px;
    }

    .about-us-content {
        flex-direction: row;
        justify-content: space-between;
      }
    
      .about-us-text {
        width: 50%;
      }
    
      .about-us-spline-container {
        width: 40%;
        height: 400px;
      }

}

/* Add support for dark mode preference */
@media (prefers-color-scheme: dark) {
    :root {
        --primary-color: #000000;
        --secondary-color: #FFFFFF;
        --accent-color: #64B5F6;
        --text-color: #E0E0E0;
        --text-color-muted: #9E9E9E;
        --background-dark: #000000;
        --card-background: #1E1E1E;
    }
}

/* Ensure proper text sizing on different devices */
html {
    font-size: 16px;
}

@media screen and (min-width: 320px) {
    html {
        font-size: calc(16px + 6 * ((100vw - 320px) / 680));
    }
}

@media screen and (min-width: 1000px) {
    html {
        font-size: 22px;
    }
}

/* Add support for high-resolution displays */
@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    /* Add high-resolution background images if necessary */
}


/* Additional styles for the new card design */
.card {
    transition: transform 0.3s ease;
}

.card:hover {
    transform: translateY(-10px);
}

.card-inner {
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}

.card-front, .card-back {
    padding: 20px;
}

.card-back {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.card-back p {
    color: var(--text-color);
}

.card .highlight {
    color: var(--accent-color);
    font-weight: 600;
}

#geometry-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    z-index: -1;
  }
  
  #geometry-container svg {
    width: 100%;
    height: 100%;
  }

/**
 * Új stílusok a kártyák elrendezéséhez különböző képernyőméreteken
 */
@media (min-width: 1201px) {
    .cards-section {
        justify-content: space-between;
    }

    .card {
        width: calc(33.33% - 20px);
    }
}

@media (min-width: 1025px) and (max-width: 1200px) {
    .cards-section {
        justify-content: space-around;
    }

    .card {
        width: calc(50% - 20px);
        margin-bottom: 40px;
    }
}

@media (max-width: 1024px) {
    .cards-section {
        flex-direction: column;
        align-items: center;
    }

    .card {
        width: 80%;
        max-width: 400px;
        margin-bottom: 40px;
    }
}

/**
 * Új stílusok az árak szekcióhoz Safari böngészőben
 */
 @media not all and (min-resolution:.001dpcm) { 
    @supports (-webkit-appearance:none) and (stroke-color:transparent) {
        .pricing-container {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
        }

        @media (min-width: 1025px) {
            .pricing-card {
                flex: 0 1 calc(33.33% - 20px);
                margin: 10px;
            }
        }

        @media (max-width: 1024px) {
            .pricing-card {
                flex: 0 1 100%;
                max-width: 350px;
            }
        }
    }
}